import * as React from 'react';
import {graphql} from 'gatsby';
import {SingleSpeakerQueryDAO, Speaker} from "../models/Speaker";
import {Section} from "../components/Section";
import NorthAmericaBasePageLayout from "../layouts/NorthAmericaBasePageLayout";
import SLink from "../components/soul/link/SLink";
import {AnchorType} from "../components/soul/link/LinkTypes";
import {Icons} from "../components/soul/icon/IconsSet";
import Grid from "../components/soul/grid/Grid";
import {GridDirection} from "../components/soul/grid/GridDirection";
import {GridDensity} from "../components/soul/grid/GridDensity";
import GridItem from "../components/soul/grid/GridItem";
import Image from "../components/Image";
import {getImage} from 'gatsby-plugin-image';
import IndiaBasePageLayout from "../layouts/IndiaBasePageLayout";

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
interface SpeakerDetailsPageProps {
    data: SingleSpeakerQueryDAO;
    pageContext: {
        slug: string;
        timezone: string;
    };
}

const SpeakerDetailsPage: React.FC<SpeakerDetailsPageProps> = ({data, pageContext}) => {
    const location = pageContext.slug.split('/speakers').shift();
    const baseLayout = location === '/india' ? IndiaBasePageLayout : NorthAmericaBasePageLayout;
    const speaker = Speaker.deserializeSingleSpeakerQuery(data, pageContext.timezone);
    const sortedSpeeches = speaker.speeches.sort((a, b) => a.order - b.order);
    const image = getImage(speaker.avatar);
    const seoData = {
        title: `UM23 | Listen to ${speaker.fullName} from ${speaker.organization}`,
        description: `Listen to ${speaker.fullName} and learn how ${speaker.organization} applied ESTECO software solutions to drive the design process.`,
        image: image?.images.fallback?.src,
        schemaMarkup: {
            '@context': 'https://schema.org/',
            '@type': 'Person',
            'name': `${speaker.fullName}`,
            'image': `${speaker.avatar?.images?.fallback?.src}`,
            'jobTitle': `${speaker.jobTitle}`,
            'worksFor': {
                '@type': 'Organization',
                'name': `${speaker.organization}`
            }
        }
    };

    return React.createElement(baseLayout, {location: `${location}/speakers`, seo: seoData},
        <>
            <Section>
                <SLink to={`${location}/speakers`} linkType={AnchorType.GHOST} icon={Icons.BACKWARD}>
                    Back to all the speakers
                </SLink>
            </Section>
            <Section>
                <Grid direction={GridDirection.HORIZONTAL} density={GridDensity.COMFORTABLE}>
                    <GridItem colSpan={8}>
                        <div className="soul-avatar soul-avatar--user soul-avatar--xl">
                            <div className="soul-avatar__thumbnail">
                                <Image imgObject={speaker.avatar} className="soul-avatar__image"
                                       alt={speaker.fullName}/>
                            </div>
                        </div>
                        <div className="soul-content soul-space-stack-top-xl">
                            <span className="soul-label soul-label--strong">{speaker.fullName}</span>
                            <span
                                className="soul-font-size-s soul-label soul-label--reduced word-break">{speaker.jobTitle}</span>
                            <span
                                className="soul-font-size-xs soul-label soul-label--reduced h-text-bold word-break">{speaker.organization}</span>
                        </div>
                        <div className="soul-content soul-space-stack-top-xl">
                            <div dangerouslySetInnerHTML={{__html: speaker.html}}></div>
                        </div>
                    </GridItem>
                    {sortedSpeeches.length > 0
                        ? <GridItem colSpan={4}>
                            <h2 className="soul-heading soul-heading--h2">Talks</h2>
                            {sortedSpeeches.map(speech =>
                                <div className="soul-card  soul-card--quiet-primary  soul-card--regular soul-space-stack-top-l" key={speech.id}>
                                    <div>
                                        {speech.startDate}&nbsp;
                                        {speech.startTime !== '12:00 am'
                                        ? <>
                                                <span className="to-uppercase">{speech.startTime} - {speech.endTime}</span>&nbsp;
                                                <span className="soul-font-size-xs soul-label--reduced">&nbsp;{speech.timezone}</span>
                                        </>
                                        : ''}
                                    </div>
                                    <div className="soul-space-stack-both-xs">
                                        <span className="soul-font-size-m h-text-bold">{speech.title}</span>
                                    </div>
                                </div>
                            )}
                        </GridItem>
                        : ''
                    }
                </Grid>
            </Section>
        </>
    );
};

export const query = graphql`
    query RetrieveSpeaker($slug: String!) {
      markdownRemark(fields: {slug: {eq: $slug}}) {
        frontmatter {
          firstName
          lastName
          jobTitle
          organization
          avatar {
            childImageSharp {
              gatsbyImageData
            }
          }
          speeches {
            childMarkdownRemark {
                fields {
                    slug
                }
              frontmatter {
                endDateTime
                startDateTime
                title
              }
            }
          }
        }
        id
        html
  }
}

`;

export default SpeakerDetailsPage;
